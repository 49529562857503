<template>
  <div class="agent">
    <div class="banner">
      <img src="../assets/images/contentImgs/banner_road.jpg" />
      <div class="bannerWord">
        <div class="line1"></div>
        <div class="line2"></div>
        <div class="line3">
          <img src="../assets/images/find.svg" />
        </div>
        <div class="content">
          <div>胜于捷</div>
          <div>汇于心，锐于行</div>
        </div>
        <div class="encontent">
          <div>THE WIN COMES FROM DILLGENCE</div>
          <div>OUR EXPERTISE FOR YOU FAST</div>
          <div>AND CONVENIENT</div>
        </div>
        <div class="lightL">
          <img src="../assets/images/light.svg" />
        </div>
      </div>
    </div>
    <!-- <Breadcrumb ></Breadcrumb> -->
    <div class="fanshapeds">
      <div class="fanshapedTitle">
        <h1>- 进出口解决方案 -</h1>
      </div>
      <div class="fanshapedDesc" id="d" ref="test">
        <div>
          <Fanshaped></Fanshaped>
        </div>
        <div>
          <div>
            <h2>散货仓单证</h2>
            <span>
              企业货物出口散货运输，入金运达仓、外运仓、力又仓、文辉仓等散货仓，可为企业提供货物入仓出口报关资料，手续简单，通关便捷的服务。
            </span>
          </div>
          <div>
            <h2>进口代理</h2>
            <span
              >以深圳机场海关、皇岗海关、盐田海关及广州黄埔老港海关等口岸为依托，代理进口货物的进口清关服务。</span
            >
          </div>
        </div>
        <div>
          <div>
            <h2>出口代理</h2>
            <span
              >代理深圳所有口岸出口，专业申报电子产品、家具、服装等传统货物出口代理。</span
            >
          </div>
          <div>
            <h2>国际快递单证服务</h2>
            <span
              >DHL、UPS、Fedex等国际快递公司出口申报报关资料服务，免除向各报关公司"买单报关"的高额费用，降低企业成本，同时提升出口效率。</span
            >
          </div>
          <div>
            <h2>木材进口</h2>
            <span
              >专业代理各类红木、建材板材、原木的进口清关服务，多年的濒危红种清关经验。</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="detailDesc">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import Fanshaped from "../components/plugin/fanshaped.vue";
// import Breadcrumb from "../components/plugin/breadcrumb.vue";
export default {
  data() {
    return {};
  },
  components: { Fanshaped },
};
</script>
<style lang="less" scoped>
.agent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}
@import url("../assets/css/banner.less");
.fanshapeds {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 600px;
  margin: 0 5%;
  .fanshapedTitle {
    line-height: 110px;
    text-align: center;
    h1 {
      text-align: center;
      margin-bottom: 30px;
      text-shadow: 0px 0px 1px #ccc;
      font-family: "圆体-简";
      font-weight: 500;
      line-height: 30px;
      color: #495961;
    }
  }
  .fanshapedDesc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin: 0 5%;
    height: 100%;
    font-size: 13px;
    position: relative;
    h2 {
      font-size: 18px;
      line-height: 25px;
      position: relative;
      font-family: "圆体-简";
    }
    h2::after {
      content: "";
      width: 100%;
      border-bottom: 1px solid #4b4b4b;
      position: absolute;
      left: 0;
      bottom: -10px;
    }
    span {
      font-size: 13px;
      color: #4a4a4a;
    }
    > div:nth-child(1) {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60%;
    }
    > div:nth-child(2) {
      width: 22%;
      height: 100%;
      position: absolute;
      left: 2%;
      > div {
        margin-bottom: 50px;
      }
    }
    > div:last-child {
      width: 22%;
      height: 100%;
      position: absolute;
      right: 2%;
      > div {
        margin-bottom: 30px;
      }
    }
    @media screen and (max-width: 1000px) {
      > div:nth-child(1) {
        transform: scale(0.8);
      }
    }
  }
  @media screen and (max-width: 768px) {
    .fanshapedDesc {
      flex-direction: column;
      > div:nth-child(2) {
        position: relative;
        width: 70%;
        left: 0;
        right: 0;
        bottom: 0;
      }
      > div:nth-child(3) {
        position: relative;
        left: 0;
        right: 0;
        bottom: 0;
        width: 70%;
        > div {
          margin-bottom: 50px;
        }
      }
    }
  }
}

.detailDesc {
  width: 100%;
}
</style>