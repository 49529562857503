<template>
  <div class="fanshaped">
    <div class="fs">
      <div class="lable">
        <div class="t1"><span>进 |</span>出口代理</div>
      </div>

      <div
        v-for="(item, key) in fanshapedDatas"
        :key="key"
        :class="'out' + (key + 1) + ' outbox'"
      >
       <a :href="item.url">
          <div :class="'inbox z' + (key + 1)" >
            <div class="content">
              <div class="num">0{{ key + 1 }}</div>
              <div class="text">
                {{ item.titleName }}
                <div class="smallTxt">{{ item.desc }}</div>
              </div>
              <div class="icon">
                <img :src="require('../../assets/images/' + item.icon)" />
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fanshapedDatas: [
        {
          url: "/agent/lcl",
          icon: "fcl.svg",
          titleName: "散货仓单证",
          desc: "海运散货入仓报关资料制作",
        },
        {
          url: "/agent/import",
          icon: "import.svg",
          titleName: "进口代理",
          desc: "深圳各机场陆运口岸进口清关",
        },

        {
          url: "/agent/export",
          icon: "export.svg",
          titleName: "出口代理",
          desc: "深圳口岸出口报关代理",
        },
        {
          url: "/agent/expressdoc",
          icon: "worldexpress.svg",
          titleName: "国际快递单证服务 ",
          desc: "UPS、DHL、Fedex等各大快递公司报关文件",
        },
        {
          url: "/agent/woodimport",
          icon: "wood.svg",
          titleName: "木材进口",
          desc: "各类红木、建材、规格料代理进口",
        },
      ],
    };
  },
  methods: {
  },
};
</script>
<style lang="less" scoped>
.fanshaped {
  margin: 20px 0 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
//灰蓝大圆
.fs {
  width: 400px;
  height: 400px;
  background-color: #384751;
  border-radius: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-15deg);

  .lable {
    position: absolute;
    z-index: 10;
    transform: rotate(15deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // opacity: 0.3;
    width: 140px;
    height: 140px;
    font-family: "圆体-简";
    .t1 {
      font-size: 18px;
      span {
        color: #e15d34;
        padding-right: 5px;
      }
    }
  }
}

//内部底层白圆
.fs::after {
  content: "";
  width: 200px;
  height: 200px;
  background-color: white;
  border-radius: 100%;
}
// 内部白圆，遮挡
.fs::before {
  content: "";
  width: 180px;
  height: 180px;
  position: absolute;
  background: white;
  border-radius: 100%;
  z-index: 10;
}
//每个小半圆
.outbox {
  width: 260px;
  height: 260px;
  box-sizing: border-box;
  position: absolute;
  right: calc(400px / 2);
  bottom: calc(400px / 2);
  opacity: 0.95;
  transform-origin: right bottom;
}

.out1 {
  transform: rotate(0deg) skew(23deg);
  overflow: hidden;
}
.out2 {
  transform: rotate(calc(90deg - 23deg + 5deg)) skew(23deg);
  overflow: hidden;
}
.out3 {
  transform: rotate(calc((90deg - 23deg + 5deg) * 2)) skew(23deg);
  overflow: hidden;
}
.out4 {
  transform: rotate(calc((90deg - 23deg + 5deg) * 3)) skew(23deg);
  overflow: hidden;
}
.out5 {
  transform: rotate(calc((90deg - 23deg + 5deg) * 4)) skew(23deg);
  overflow: hidden;
}
.inbox {
  width: 210px;
  height: 210px;
  background-color: red;
  position: absolute;
  transform-origin: right bottom;
  transform: skew(-23deg) rotate(0);
  border-top-left-radius: 100%;
  bottom: 0;
  right: 0;
  transition: all 0.5s ease-in-out;
  box-sizing: border-box;
  border: 1px solid #eaeaea;
  z-index: 6;
  display: block;
  clip-path: circle(100% at 100% 100%);
  -webkit-clip-path: circle(100% at 100% 100%);
  .content {
    display: inline-flex;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    color: white;
    opacity: 1;
    .num {
      position: absolute;
      // background: red;
      display: flex;
      width: 14%;
      font-size: 25px;
      padding: 5px;
      font-family: "黑体";
    }
    .text {
      // background: green;
      position: absolute;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      font-weight: 500;
      flex-direction: column;
      text-shadow: 1px 1px 2px #333;
      .smallTxt {
        text-align: center;
        width: 80%;
        font-size: 12px;
        font-weight: 400;
        color: #ddd;
      }
    }

    .icon {
      width: 13%;
      position: absolute;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.inbox:hover {
  width: 250px;
  height: 250px;
}

.zout {
  width: 250px;
  height: 250px;
}
.z1 {
  background-color: #576a74;
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    .num {
      left: 0;
      top: 80%;
      transform: rotate(-90deg);
    }
    .text {
      margin-top: 40px;
      width: 60%;
      left: 20px;
      transform: rotate(15deg);
    }
    .icon {
      transform: rotate(-26deg);
      left: 52%;
      top: 12%;
    }
  }
}
.z2 {
  background-color: #e15d34;
  .content {
    transform: rotate(-72deg);
    position: absolute;
    top: 30px;
    left: 20px;
    .text {
      left: 0;
      width: 100%;
      padding-top: 45px;
      justify-content: center;
      transform: rotate(15deg);
      .smallTxt {
        width: 50%;
      }
    }
    .icon {
      transform: rotate(49deg);
      left: 85%;
      top: 29%;
    }
  }
}
.z3 {
  background-color: #94a1ac;
  .content {
    transform: rotate(-90deg);
    .text {
      width: 60%;
      transform: rotate(-40deg);
      top: 55px;
      left: 25px;
    }
    .icon {
      transform: rotate(60deg);
      left: 74%;
      top: 51%;
    }
  }
}
.z4 {
  background-color: #828d91;
  .content {
    transform: rotate(144deg);
    .num {
      transform: rotate(135deg);
      right: -35px;
      top: 30%;
    }
    .text {
      padding-top: 68px;
      padding-left: 70px;
      transform: rotate(13deg);
    }
    .icon {
      transform: rotate(-172deg);
      left: 20%;
      top: 63%;
    }
  }
}
.z5 {
  background-color: #71828a;
  .content {
    transform: rotate(72deg);
    position: absolute;
    .num {
      transform: rotate(-150deg);
      left: 68%;
      top: 90%;
    }
    .text {
      left: 0;
      justify-content: center;
      padding-top: 106px;
      padding-left: 75px;
      transform: rotate(15deg);
    }
    .icon {
      transform: rotate(-100deg);
      left: 18%;
      top: 25%;
    }
  }
}
</style>